<template>
	<div class="screen-wrapper deep-register-view">
		<div
			class="roundup-alert roundup-alert-info"
			:style="{ maxWidth: '1280px', textAlign: 'center' }"
			v-if="brandComplete"
		>
			<h3>Great Job! Step 2 Complete</h3>
			<router-link :to="{ name: 'steps' }"
				><ui-button>Return to Onboarding</ui-button></router-link
			>
		</div>
		<screen size="large">
			<h1 slot="title" :style="{ color: 'black' }">
				Your Custom Donor Registration Page -
				<span class="link" @click="goToCustomPage()"
					>https://roundup.app/p/{{ institution.username }}</span
				>
			</h1>
			<p slot="subtitle" class="subtitle">
				Use the link above when promoting RoundUpApp - it links donors
				directly to your organization and has been shown to increase
				conversion rates! The screen below is what your donors seen when
				you send out the link.
				<strong
					>The page below is an example of what your donors will see
					when they register.</strong
				>
			</p>
			<link rel="stylesheet" href="https://use.typekit.net/rhi6acd.css" />
			<div class="deep-register-parts">
				<div
					:class="[
						'deep-register-half',
						'deep-register-emotion',
						(currentTutorialStep === 1 || 2) && 'show'
					]"
				>
					<div :class="['register-images']">
						<div class="overlay" v-if="currentTutorialStep === 2" />
						<img
							v-if="hasCoverImage"
							class="cover"
							@error="downgradeImage"
							:src="institution.image_url"
						/>
						<div v-else class="add-cover">
							<div class="image-upload-buttons">
								<div class="upload-btn-wrapper grow">
									<button
										:class="[
											'btn',
											{ loading: isCoverLoading },
											'grow'
										]"
									>
										Upload Cover Photo
									</button>
									<input
										type="file"
										name="file"
										:disabled="isCoverLoading"
										@change="
											triggerCoverUpload(
												$event.target.name,
												$event.target.files
											)
										"
										accept="image/*"
										class="input-file"
									/>
								</div>
							</div>
						</div>
						<img
							v-if="hasImages"
							class="logo"
							:src="institution.logo_url"
						/>
						<div v-else class="add-logo">
							<div class="upload-btn-wrapper grow">
								<button
									:class="['btn', { loading: isLoading }]"
									:style="{ height: '75px' }"
								>
									Upload Logo
								</button>
								<input
									type="file"
									name="file"
									:disabled="isLoading"
									@change="
										triggerLogoUpload(
											$event.target.name,
											$event.target.files
										)
									"
									accept="image/*"
									class="input-file"
								/>
							</div>
						</div>
						<div class="image-upload-custom">
							<div class="image-upload-buttons">
								<div
									class="upload-btn-wrapper"
									v-if="hasImages"
								>
									<button
										:class="['btn', { loading: isLoading }]"
									>
										Change Logo
									</button>
									<input
										type="file"
										name="file"
										:disabled="isLoading"
										@change="
											triggerLogoUpload(
												$event.target.name,
												$event.target.files
											)
										"
										accept="image/*"
										class="input-file"
									/>
								</div>
								<div
									class="upload-btn-wrapper"
									v-if="hasCoverImage"
								>
									<button
										:class="[
											'btn',
											{ loading: isCoverLoading }
										]"
									>
										Change Cover Photo
									</button>
									<input
										type="file"
										name="file"
										:disabled="isCoverLoading"
										@change="
											triggerCoverUpload(
												$event.target.name,
												$event.target.files
											)
										"
										accept="image/*"
										class="input-file"
									/>
								</div>
							</div>
						</div>
					</div>
					<div :style="{ position: 'relative' }">
						<div
							class="overlay"
							:style="{
								right: '-40px',
								left: '-40px',
								bottom: '-18px'
							}"
							v-if="currentTutorialStep === 1"
						/>
						<div class="tooltip" @click="edit('impact_statement')">
							<h1>{{ institution.impact_statement }}</h1>
							<img
								class="edit_img"
								alt="Edit Created by Shiva from the Noun Project"
								src="@/assets/edit.png"
							/>
							<span class="tooltiptext">Click to edit</span>
						</div>
						<div
							class="tooltip"
							@click="edit('full_impact_statement')"
						>
							<p>
								Donating the spare change from your credit or
								debit card purchases allows us to
								{{ institution.full_impact_statement }}.
							</p>
							<img
								class="edit_img"
								alt="Edit Created by Shiva from the Noun Project"
								src="@/assets/edit.png"
							/>
							<span class="tooltiptext">Click to edit</span>
						</div>
						<div
							v-if="currentTutorialStep === 2"
							class="edit_tutorial"
						/>
					</div>
					<div :style="{ position: 'relative' }">
						<div
							class="overlay"
							:style="{ right: '-40px', left: '-40px' }"
							v-if="currentTutorialStep > -1"
						/>
						<p>
							<strong
								>Let's get you started supporting
								{{ institution.name }}.</strong
							>
						</p>
						<div class="register-form">
							<form v-on:submit.prevent="edit('call_to_action')">
								<ui-label>
									<ui-input
										:disabled="true"
										placeholder="First Name"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										:disabled="true"
										placeholder="Last Name"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										:disabled="true"
										type="number"
										placeholder="Cell Phone"
									></ui-input>
								</ui-label>
								<ui-label>
									<ui-input
										:disabled="true"
										placeholder="Email"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										:disabled="true"
										placeholder="Password"
										type="password"
									/>
								</ui-label>
								<label>
									<p class="terms">
										By continuing, I confirm I am a U.S.
										resident, 13 years or older and agree to
										the
										<a href="https://roundupapp.com/terms"
											>Terms of Use</a
										>.
									</p>
									<ui-button>Register For RoundUps</ui-button>
								</label>
							</form>
						</div>
					</div>
				</div>
				<!-- <div class="deep-register-half deep-register-steps">
                    <div class="show">
                        <div class="roundup-alert roundup-alert-info" style="marginTop: -30px">Cover Photos should have a 2:1 ratio with a height of at least 500px. Logos should be 1:1 with a hight of at least 200px.</div>
                    </div>
                    <h2>What is this?</h2>
                        <div class="step">
                            <h3><small>1.</small>Donors will see everything in the white box when they register</h3>
                            <p>Organizations that use their custom link are much more successful than those that don't</p>

                        </div>
                        <div class="step">
                            <h3><small>2.</small> Make sure your information accurately shows your branding!</h3>
                            <p>It helps donors recognize you. Feel free to edit any information with the <img src="@/assets/edit.png" :style="{height: '20px', width: 'auto', margin: 'auto 5px'}"/> symbol. Just click on it.</p>
                        </div>
                        <div class="step">
                            <h3><small>3.</small>The littlest things make the difference</h3>
                            <p>Please use high quality images, make your call to action clear (the button), and make your impact statement (the header) empassioned. It helps donors connect with your organization. </p>
                        </div>
                </div> -->
			</div>
			<modal v-model="showEditModal">
				<h3>{{ editValue.label }}</h3>
				<p v-if="editValue.value == 'impact_statement'">
					Fill in this sentence with a statement of the impact donors
					will be making: "<strong>{{ impact_statement }}</strong>
					Just by Donating Your Change." (Example: "Feed Hungry
					Children," or "Support Urban Justice.")
				</p>
				<p v-if="editValue.value == 'full_impact_statement'">
					Fill in this sentence with a statement of the impact donors
					will be making: "Donating the spare change from your credit
					or debit card purchases allows us to
					<strong>{{ full_impact_statement }}</strong
					>." (Example: "provide lifesaving food for children and
					their families")
				</p>
				<p v-else>{{ editValue.subtitle }}</p>
				<form v-on:submit.prevent="update">
					<ui-label>
						<ui-input
							v-model="institution[editValue.value]"
							placeholder="Enter your statement here"
							:maxlength="editValue.max"
						/>
					</ui-label>
					<ui-button native-type="submit" :loading="isLoading"
						>Save</ui-button
					>
				</form>
			</modal>
			<modal v-model="showTutorialModal">
				<h3>Welcome to your Custom Donor Registration Page</h3>
				<p>
					We are going to go through a quick tutorial to make sure
					everything gets completed.
				</p>
				<p :style="{ fontSize: '14px' }">
					You can exit at anytime by pressing the "X" in the top
					right. If you do, make this page your own by editing any
					text that has a
					<img
						src="@/assets/edit.png"
						:style="{
							height: '20px',
							width: 'auto',
							margin: 'auto 5px'
						}"
					/>
					next to it. Just click on the text to edit.
				</p>
				<ui-button @click="nextTutorial">Next</ui-button>
			</modal>
		</screen>
		<div class="overlay" v-if="currentTutorialStep > -1">
			<div :style="{ position: 'absolute', top: '10px', right: '20px' }">
				<ui-button @click="closeTutorial" type="white"
					>Close Tutorial</ui-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { get } from '../../../utilities'

import Screen from '../../ui/Screen'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiButton from '../../ui/Button'
import Modal from '../../ui/Modal'

export default {
	name: 'profile-view',
	components: { Screen, UiInput, UiLabel, UiButton, Modal },
	data() {
		return {
			showSuccess: false,
			institution: cloneDeep(this.$store.state.institution.item),
			isLoading: false,
			isCoverLoading: false,
			showEditModal: false,
			editValue: {},
			showTutorialModal: false,
			currentTutorialStep: -1,
			brandComplete: false,
			editImpact: false,
			editFullImpact: false
		}
	},
	computed: {
		...mapGetters([
			'hasImages',
			'hasCoverImage',
			'onboardingStep',
			'hasBrand'
		]),
		impact_statement() {
			return get(this.institution, 'impact_statement', '________________')
		},
		full_impact_statement() {
			return get(
				this.institution,
				'full_impact_statement',
				'________________'
			)
		},
		cover_photo_url() {
			return (
				'https://s3.amazonaws.com/roundup-website/cover/' +
				this.institution.uuid +
				'.jpg'
			)
		},
		deeplink() {
			return 'https://roundup.app/p/' + this.institution.username
		}
	},
	methods: {
		...mapActions([
			'uploadLogo',
			'uploadCover',
			'updateInstitution',
			'getInstitutionDetail'
		]),
		onSubmit() {
			this.$swal(
				'Submit!',
				'Your donors will then continue the registration process. This is just a demo page for you to experience what one of your donors would.'
			)
		},
		nextTutorial() {
			this.showTutorialModal = false
			this.currentTutorialStep = 1
		},
		closeTutorial() {
			this.currentTutorialStep = -1
		},
		triggerLogoUpload(fieldName, files) {
			this.isLoading = true
			const formData = new FormData()
			if (files.length <= 0) return
			formData.append(fieldName, files[0], files[0].name)
			this.uploadLogo(formData)
				.then(data => {
					this.institution.logo_url = data
					this.isLoading = false
					if (this.hasCoverImage && this.currentTutorialStep === 1) {
						this.currentTutorialStep = 2
					}
					this.$swal(
						'success',
						'Your image has been uploaded and is processing. It could be up to an hour before it appears before users.'
					)
				})
				.catch(() => {
					this.isLoading = false
					this.$swal('Uh Oh!', 'Please try again.')
				})
		},
		triggerCoverUpload(fieldName, files) {
			this.isCoverLoading = true
			const formData = new FormData()
			if (files.length <= 0) return
			formData.append(fieldName, files[0], files[0].name)
			this.uploadCover(formData)
				.then(data => {
					this.institution.image_url = data
					this.isCoverLoading = false
					if (this.hasImages && this.currentTutorialStep === 1) {
						this.currentTutorialStep = 2
					}
					this.$swal(
						'success',
						'Your image has been uploaded and is processing. It could be up to an hour before it appears before users.'
					)
				})
				.catch(() => {
					this.isCoverLoading = false
					this.$swal('Uh Oh!', 'Please try again.')
				})
		},
		appendToFilename(filename, string) {
			var dotIndex = filename.lastIndexOf('.')
			if (dotIndex === -1) return filename + string
			else
				return (
					filename.substring(0, dotIndex) +
					string +
					filename.substring(dotIndex)
				)
		},
		downgradeImage(el) {
			el.srcElement.src = this.institution.image_url
		},
		onlyNumber(evt) {
			const clean = evt.replace(/\D+/g, '')
			if (clean !== evt) {
				this.message = 'Numbers only please :)'
			} else {
				this.message = null
			}
			this.user.phone = clean
		},
		goToCustomPage() {
			if (!(this.hasImages && this.hasCoverImage)) {
				this.$swal(
					'Your Page Is Not Live',
					'Please upload a cover photo and logo first. It will then be set live automatically and your donors can begin registering.'
				)
			} else {
				window.location.href =
					'https://roundup.app/p/' + this.institution.username
			}
		},
		edit(value) {
			this.editValue = this.editData(value)
			this.showEditModal = true
		},
		editData(value) {
			const data = {
				label: '',
				subtitle: '',
				placeholder: '',
				max: null,
				description: '',
				value
			}
			switch (value) {
				case 'impact_statement':
					this.editImpact = true
					data.label = 'Impact Statment - Big Idea'
					data.placeholder = this.institution.impact_statement
					data.max = 30
					return data
				case 'full_impact_statement':
					this.editFullImpact = true
					data.label = 'Impact Statement - Tangible Change'
					data.placeholder = this.institution.full_impact_statement
					data.max = 70
					return data
				case 'call_to_action':
					data.label = 'Call To Action'
					data.placeholder = this.institution.call_to_action
					data.subtitle =
						'Provide the text for the call to action button that donors will click to begin giving. Examples: "Feed a Child", "Join the Fight", or "Invest in Nature."'
					data.max = 20
					return data
				default:
					return data
			}
		},
		update() {
			this.isLoading = true
			this.updateInstitution(this.institution)
				.then(() => {
					this.showEditModal = false
					this.isLoading = false
					if (this.currentTutorialStep > 0) {
						if (this.editImpact + this.editFullImpact === 1) {
							this.$swal({
								title: 'Success!',
								text:
									'Your information has successfully been updated. One last step...',
								confirmButtonText: 'Next Step',
								allowOutsideClick: true,
								allowEscapeKey: false
							}).then(result => {
								if (result.value) {
									if (this.editImpact) {
										this.edit('full_impact_statement')
									} else {
										this.edit('impact_statement')
									}
								}
							})
							return
						}

						this.$swal({
							title: 'Success! Step 2 Complete',
							text:
								'Your information has successfully been updated.',
							confirmButtonText: 'Return to Onboarding',
							showCancelButton: true,
							cancelButtonText: 'Continue Editing',
							allowOutsideClick: true,
							allowEscapeKey: false
						}).then(result => {
							if (result.value) {
								this.navigate({ name: 'steps' })
								return
							}
							this.currentTutorialStep = -1
							this.brandComplete = true
						})
					} else {
						this.$swal(
							'Success',
							'Your information has successfully been updated.'
						)
					}
				})
				.catch(({ response }) => {
					this.showEditModal = false
					this.isLoading = false
					this.$swal('Error', response.data.message)
				})

			return false
		}
	},
	mounted() {
		if (!this.hasBrand) {
			this.showTutorialModal = true
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.deep-register-view {
	.subtitle {
		margin: 0;
		color: $roundup-medium-grey;
	}
	@media screen and (max-width: 960px) {
		width: 100%;
		left: 0px;
	}
	h1 {
		font-size: 42px;
		font-weight: 500;
		line-height: 48px;
		color: #111;
		margin: 15px 0px 10px;
	}
	h2 {
		font-size: 36px;
		font-weight: 500;
		line-height: 48px;
		color: #111;
		margin: 10px 0px;
	}
	h3 {
		font-size: 22px;
		font-weight: 600;
		color: #111;
		margin: 10px 0px 0px;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: #111;
	}
	.deep-register-parts {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		@media screen and (max-width: 960px) {
			display: block;
		}
	}
	.register-images {
		position: relative;
		margin-left: -40px;
		margin-right: -40px;
		margin-top: -30px;
		height: 275px;
		overflow: hidden;
		.add-cover {
			height: 100%;
			max-width: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			border-bottom: 2px solid $roundup-light-grey;
			flex-direction: column;
			text-align: center;
			background: url('../../../assets/Default_CoverPhoto.png');
			background-size: contain;
			background-repeat: no-repeat;
		}
		.cover {
			height: 100%;
			max-width: 100%;
			width: 100%;
		}
		img {
			border-radius: 10px 10px 0px 0px;
			width: 100%;
			@media screen and (max-width: 480px) {
				border-radius: 0px;
			}
		}
		.add-logo {
			height: 75px;
			max-width: 75px;
			justify-content: center;
			align-items: center;
			display: flex;
			border-radius: 10px;
			border-color: black;
			border-width: 2px;
			position: absolute;
			left: 40px;
			bottom: 15px;
			border: 2px solid $roundup-light-grey;
			background: white;
		}
		.logo {
			max-width: 75px;
			position: absolute;
			left: 40px;
			bottom: 15px;
			border-radius: 10px;
		}
		.image-upload {
			position: relative;
		}
		.image-upload-buttons {
			padding: 10px 0px;
		}
	}
	.deep-register-half {
		padding: 0px 15px;
	}
	.deep-register-emotion {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
		padding: 30px 40px;
		width: 60%;
		flex-shrink: 0;
		@media screen and (max-width: 960px) {
			width: auto;
		}
		@media screen and (max-width: 480px) {
			border-radius: 0px;
		}
		.register-form {
			label {
				display: block;
				margin-top: 5px;
				margin-bottom: 5px;
			}
			.terms {
				font-size: 14px;
				color: #888;
				margin-bottom: 0px;
			}
		}
	}
	.deep-register-steps {
		padding: 30px 10px 30px 30px;
		@media screen and (max-width: 480px) {
			padding: 30px;
		}
		.step {
			vertical-align: top;
			padding: 10px 0px;
		}
		.columns {
			display: flex;
		}
		p {
			margin-top: 5px;
		}
		img {
			width: 25%;
			height: 25%;
		}
	}
	.roundup-button-m {
		width: auto;
		background: #184fd5;
		color: #fff !important;
		border-radius: 20px;
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		font-weight: 600;
		padding: 10px 20px;
		margin-top: 20px;
		position: relative;
		text-decoration: none;
	}
}

.profile-information {
	formpadding-top: 20px;
	.roundup-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
	}
	h3 {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 0px;
		margin-top: 40px;
		color: #71788c;
		letter-spacing: 0;
		color: $roundup-medium-grey;
	}
	p {
		color: $roundup-medium-grey;
		margin-bottom: 0px;
	}
}
.link {
	color: $roundup-primary;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.image-upload-custom {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	.btn {
		border: 1px solid $roundup-light-grey;
		color: $roundup-primary;
		background-color: white;
		border-radius: 10px;
		width: 100%;
		height: 40px;
		font-size: 14px;
		margin: 0px;
		&.loading {
			color: white;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 0px;
				height: 0px;
				border-radius: 50%;
				left: 45%;
				top: 50%;
				border: 2px solid rgba(255, 255, 255, 0.8);
				border-right: 2px solid $roundup-primary;
				animation: rotate360 0.5s infinite linear,
					exist 0.1s forwards ease;
			}
		}
	}

	&:hover .btn {
		background: $roundup-primary;
		color: $white;
		border-color: $roundup-primary;
		cursor: pointer;
	}
	&:hover .btn .loading {
		background: $roundup-primary;
		color: $white;
		border-color: $roundup-primary;
		cursor: pointer;
	}
	input[type='file'] {
		font-size: 100px;
		position: absolute;
		cursor: pointer;
		left: 0;
		top: 0;
		opacity: 0;
	}
	&.grow {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
		border-radius: 10px;
		transform: scale(1);
		animation: pulse 2s infinite;
		animation-iteration-count: 5;

		@keyframes pulse {
			0% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
			}
			70% {
				transform: scale(1);
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}
			100% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}
		}
	}
}

.edit_img {
	display: inline;
	height: 1.5em;
	margin-top: 1em;
	margin-left: 1em;
}

.tooltip {
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
}
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: $roundup-primary;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
}

.tooltip:hover .tooltiptext {
	visibility: hidden;
}

.overlay {
	top: 0;
	bottom: -400px;
	right: 0;
	left: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}

.show {
	z-index: 101;
	border-radius: 10px;
	background: white;
}

.edit_tutorial {
	background: url('../../../assets/Edit_tutorial.png');
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	height: 80px;
}
</style>
